import type { ErrorCode, ErrorIntlKey, ErrorMessage } from '@aph/utilities/errors/types';
import { ErrorDescription } from '~/services/generated/CustomerClient';

export const getErrorOrDefault = (
  error: unknown,
  errorIntlKey: string,
): ErrorIntlKey | (ErrorMessage & ErrorCode) => {
  if (error instanceof ErrorDescription && error.userMessage && error.userMessage.length !== 0) {
    const errorCode = error.code || '';
    const errorMessage = error.userMessage.join(' ');
    return { errorMessage, errorCode };
  }
  return { errorIntlKey };
};
