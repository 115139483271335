import React from 'react';
import { useRouter } from 'next/router';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import type { NextSeoProps } from 'next-seo';
import { NextSeo } from 'next-seo';
import { ErrorBoundary } from '@aph/components/error-boundary/error-boundary.container';
import { cn } from '@aph/ui/tailwind/cn';
import { InfoBar } from '~/contentful/components/info-bar/info-bar';
import { useFeatureToggle } from '~/model/feature-toggle/useFeatureToggle';
import theme from '~/styles/theme';
import { Footer } from '../common/footer/footer.component';
import { LowerNavigation } from '../common/main-navigation/lower-navigation.component';
import { TopNavigation } from '../common/main-navigation/top-navigation.component';
import { PageLayoutContainer } from './page-layout-container';

// we wrap the page layout with a theme that uses the breakpoints from the aph theme
// this is done so we don't have to use the useCompLibBreakpoints hook in every component (and avoid flickering)
const withBreakpointsFromTheme = createTheme({
  ...theme,
  breakpoints: {
    mqs: theme.breakpoints.mqs,
    keys: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
    values: {
      ...theme.breakpoints.values,
      xxl: 1920,
    },
  },
});

interface LayoutProps {
  seo: NextSeoProps;

  /**
   * Content inside the "hero" slot will end up "inside" the wave area (the divider between the hero and the page content)
   */
  hero?: React.ReactElement;
  /**
   * If you don't want the wave effect (divider), you can set this to false.
   */
  wave?: boolean;

  /**
   * Content inside the "top" slot will end up "above" the header.
   */
  top?: React.ReactElement;

  /**
   * The "header" layout slot allows you to provide a custom header that will be used instead of the default one.
   */
  header?: React.ReactElement;

  /**
   * The "sidebar" layout slot allows you to provide content that displays to the left (on medium screens and up).
   */
  sidebar?: React.ReactNode;
  children?: React.ReactNode;
}

/* some notes on the css variables used in this component:

  --grid-gap => the gap/space between the sidebar and the main content
  --content-width => the width of the main content (this is a fixed width in xl and xxl and fluid in smaller breakpoints)
  --vertical-margin => the left+right margins on either sides of the view, e.g. the main content + sidebar (if applicalble)
*/

export const PageLayout: React.FC<LayoutProps> = ({
  seo,
  top,
  header,
  hero,
  sidebar,
  wave = true,
  children,
}) => {
  const router = useRouter();
  const isLayoutWithSidebar = Boolean(sidebar);
  const isUsingCustomHeader = Boolean(header);
  const isLayoutWithWave = wave;
  const isLayoutWithHero = Boolean(hero);

  const { isEnabled: isRosaBandedToggleOn } = useFeatureToggle('aph_features_rosa_banded');

  return (
    <>
      <NextSeo {...seo} />
      <ThemeProvider theme={withBreakpointsFromTheme}>
        {top ?? <InfoBar />}
        {isUsingCustomHeader ? (
          <PageLayoutContainer className="bg-elevated">{header}</PageLayoutContainer>
        ) : (
          <>
            <PageLayoutContainer className="bg-elevated">
              <TopNavigation />
            </PageLayoutContainer>
            <PageLayoutContainer className="bg-elevated z-header sticky top-0">
              <LowerNavigation />
            </PageLayoutContainer>
          </>
        )}

        <ErrorBoundary key={router.pathname}>
          <div
            id="main-content"
            className={cn(
              'xxl:[--content-width:1400px] relative isolate z-[1] [--vertical-margin:12px] sm:[--vertical-margin:24px] md:[--vertical-margin:40px] xl:[--content-width:1112px]',
              {
                'bg-[--wave-color]': isLayoutWithWave,
                '[--grid-gap:theme(spacing.3)] md:[--sidebar-width:216px] lg:[--grid-gap:theme(spacing.4)] xl:[--sidebar-width:248px]':
                  isLayoutWithSidebar,
                '[--wave-color:#F7CED7]': isRosaBandedToggleOn,
              },
            )}
            style={{
              '--container-width': `calc(var(--content-width) + var(--sidebar-width) + var(--grid-gap))`,
              '--offset-left': `calc(var(--sidebar-width) + var(--grid-gap))`,
              '--offset-right-xl': `calc((100vw - var(--container-width)) / 2)`,
              '--offset-left-xl': `calc(var(--offset-right-xl) + var(--sidebar-width) + var(--grid-gap))`,
            }}
          >
            <div
              className={cn(`mx-auto pt-2 md:pt-4`, {
                'flex-row-reverse md:flex md:gap-[--grid-gap] xl:max-w-[--container-width]':
                  isLayoutWithSidebar,
              })}
            >
              <div
                className={cn('flex w-full flex-col', {
                  'md:max-xl:-ml-[--offset-left]': isLayoutWithSidebar,
                })}
              >
                {isLayoutWithHero ? (
                  <div
                    className={cn('pb-3 max-md:px-[--vertical-margin]', {
                      'md:max-xl:pl-[calc(var(--offset-left)_+_var(--vertical-margin))] md:max-xl:pr-[--vertical-margin]':
                        isLayoutWithSidebar,
                      'w-full md:max-xl:px-[--vertical-margin] xl:mx-auto xl:max-w-[--content-width]':
                        !isLayoutWithSidebar,
                    })}
                  >
                    {hero}
                  </div>
                ) : null}

                <div
                  className={cn('bg-default h-full', {
                    'xl:-ml-[--offset-left-xl] xl:-mr-[--offset-right-xl]': isLayoutWithSidebar,
                  })}
                >
                  {isLayoutWithWave ? <Divider /> : null}

                  <div
                    className={cn('max-md:px-[--vertical-margin]', {
                      'md:max-xl:pl-[calc(var(--offset-left)_+_var(--vertical-margin))] md:max-xl:pr-[--vertical-margin] xl:pl-[--offset-left-xl] xl:pr-[--offset-right-xl]':
                        isLayoutWithSidebar,
                      'md:max-xl:px-[--vertical-margin] xl:mx-auto xl:max-w-[--content-width]':
                        !isLayoutWithSidebar,
                      'pt-2': isLayoutWithWave,
                    })}
                  >
                    {children}
                  </div>
                </div>
              </div>

              {isLayoutWithSidebar ? <Sidebar>{sidebar}</Sidebar> : null}
            </div>
          </div>
        </ErrorBoundary>
        <Footer />
      </ThemeProvider>
    </>
  );
};

const Divider: React.FC = () => (
  <div
    className="xxl:h-[56px] relative -mt-[1px] h-[18px] w-full bg-[--wave-color] sm:h-[24px] md:h-[34px] lg:h-[40px] xl:h-[46px]"
    aria-hidden
  >
    <svg
      className="absolute inset-0 mt-[1px] h-full w-full fill-[#F6F6F6]"
      viewBox="0 0 1920 58"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M608.75 0C291.25 0 80.667 21.967 0 32.697V58h1920V38.303c-9.6 1.37-100.7 13.079-347.5 13.079C1264 51.382 974.25 0 608.75 0Z" />
    </svg>
  </div>
);

const Sidebar: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <aside className="relative w-[--sidebar-width] shrink-0 max-md:hidden md:max-xl:left-[--vertical-margin]">
    <div className="bg-elevated rounded-2xl px-1 py-2">{children}</div>
  </aside>
);
