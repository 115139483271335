import type { CONTENT_TYPE, IGenericPage, IImage } from '../types';

export type UnknownContentType = {
  sys: {
    contentType: {
      sys: {
        id: CONTENT_TYPE;
      };
    };
  };
};

export const isContentPage = (page: UnknownContentType): page is IGenericPage => {
  return page.sys.contentType.sys.id === 'genericPage';
};

export const isImage = (data: UnknownContentType): data is IImage => {
  return data.sys.contentType.sys.id === 'image';
};
