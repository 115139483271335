import type { FeatureToggleableLink } from './link-list.component';

export const PROMOTED_LINKS = [
  { title: 'Nyheter', href: '/nyheter' },
  { title: 'Kampanjer', href: '/kampanjer' },
  { title: 'Outlet', href: '/kampanjer/outlet' },
  { title: 'Varumärken', href: '/varumarken' },
  {
    title: 'Förebyggande hälsa',
    href: '/tips-och-rad/halsa/forebyggande-halsa',
  },
  {
    title: 'Kvinnohälsa',
    href: '/tips-och-rad/kvinnohalsa/ma-bra-genom-livets-alla-faser',
  },
  {
    title: 'Våra tjänster',
    href: '/vara-tjanster',
    isEpiLink: true,
  },
] satisfies Array<FeatureToggleableLink>;
